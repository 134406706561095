
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.telehealth-notification-banner-container {
		position: relative;
		display: flex;
		flex-direction: column;

		ion-slides {
			--bullet-background: var(--color-primary-lighter);
			:deep(*).swiper-pagination-bullet {
				opacity: 1;
			}
		}

		// allow pagination to show up outside of swipe container
		.swiper-container {
			overflow: unset;
			z-index: 100;
			:deep(*).swiper-pagination-bullets {
				bottom: -25px;
				pointer-events: none;
			}
		}
	}
