
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.live-chat {
		--app-bg: var(--color-kii-mental-health-primary);

		.call-indicator {
			right: 0;
			top: 0;
			&.connected {
				--pulse-color: var(--color-lime-base);
			}

			&.not-connected {
				--pulse-color: var(--color-red-base);
			}
		}
	}

	.background-white {
		background-color: white;
	}

	.conv-finished {
		--app-bg: white;
	}

	.title {
		color: var(--color-kii-medical-care);
		font-weight: 400;
		font-size: 36px;
		font-family: Marcellus, sans-serif;
	}

	.subtitle {
		@extend .title;
		font-weight: 400;
		font-size: 20px;
	}
	.nurse-name {
		color: var(--color-kii-medical-care);
		font-family: EuclidCircularA, sans-serif;
		font-size: 20px;
		font-weight: 600;
		line-height: 25px;
	}
	.chatting-with {
		color: var(--color-kii-medical-care);
		font-family: EuclidCircularA, sans-serif;
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
	}
	.nurse-avatar {
		box-shadow: 1px 1px 0 0 rgba(8, 8, 8, 0.08);
	}

