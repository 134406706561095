
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.close-button {
		height: 20px;
		width: 20px;
		color: var(--color-kii-medical-care);
		--background-hover: black;
		--background-hover-opacity: 0.1;
		border-radius: 50%;
		position: relative;
		top: 20px;
		right: 8px;
	}

	.content-wrapper {
		z-index: 900000;

		.feedback-overlay {
			display: flex;
			flex-direction: column;
			height: 100%;
			width: 100%;

			position: fixed;
			top: 0;
			left: 0;
			z-index: 900000;
		}

		.title {
			font-family: Marcellus,serif;
			font-weight: 400;
			font-size: 32px;
			line-height: 40px;
			align-content: center;
			text-align: center;
			color: var(--color-kii-medical-care);
		}

		.subtitle {
			@extend .title;
			font-size: 20px;
			line-height: 25px;
		}

		.feedback-box {
			font-size: 12px;
			font-family: EuclidCircularA, serif;
		}

		.character-count {
			font-family: EuclidCircularA, serif;
			font-size: 13px;
		}

		.selector-item {
			flex: 0 0 30%;
			height: 30px;
		}

		.selected {
			border-color: var(--color-kii-light-teal);
			border-style: solid;
			border-radius: 12px;
			border-width: 2px;
		}

	}
