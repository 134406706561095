@mixin whitelabel-variables-corporate()
{
	/* legacy whitelabel color variables. Just use the normal --color-XXXX-XXXX variables  */
	--color-whitelabel-primary-darkest: var(--color-primary-darkest);
	--color-whitelabel-primary-darkest-alpha: var(--color-primary-darkest-alpha);
	--color-whitelabel-primary-darker: var(--color-primary-darker);
	--color-whitelabel-primary-dark: var(--color-primary-dark);
	--color-whitelabel-primary-base: var(--color-primary-base);
	--color-whitelabel-primary-light: var(--color-primary-light);
	--color-whitelabel-primary-lighter: var(--color-primary-lighter);
	--color-whitelabel-primary-lightest: var(--color-primary-lightest);

	--color-whitelabel-secondary-darkest: var(--color-secondary-darkest);
	--color-whitelabel-secondary-darker: var(--color-secondary-darker);
	--color-whitelabel-secondary-dark: var(--color-secondary-dark);
	--color-whitelabel-secondary-base: var(--color-secondary-base);
	--color-whitelabel-secondary-light: var(--color-secondary-light);
	--color-whitelabel-secondary-lighter: var(--color-secondary-lighter);
	--color-whitelabel-secondary-lightest: var(--color-secondary-lightest);

	--ion-color-primary: var(--color-primary-base);
}

@mixin corporate-color-set()
{
	// corporate colors. Use these if you don't want the color to be overridden by organization color sets.
	--color-corporate-primary-darkest: var(--color-primary-darkest);
	--color-corporate-primary-darkest-alpha: var(--color-primary-darkest-alpha);
	--color-corporate-primary-darker: var(--color-primary-darker);
	--color-corporate-primary-dark: var(--color-primary-dark);
	--color-corporate-primary-base: var(--color-primary-base);
	--color-corporate-primary-light: var(--color-primary-light);
	--color-corporate-primary-lighter: var(--color-primary-lighter);
	--color-corporate-primary-lightest: var(--color-primary-lightest);

	--color-corporate-secondary-darkest: var(--color-secondary-darkest);
	--color-corporate-secondary-darker: var(--color-secondary-darker);
	--color-corporate-secondary-dark: var(--color-secondary-dark);
	--color-corporate-secondary-base: var(--color-secondary-base);
	--color-corporate-secondary-light: var(--color-secondary-light);
	--color-corporate-secondary-lighter: var(--color-secondary-lighter);
	--color-corporate-secondary-lightest: var(--color-secondary-lightest);
}

:root {
	// MyHealthAccess Palette
	--color-mha-primary-darkest: #16243f;
	--color-mha-primary-darkest-alpha: 22, 36, 63;
	--color-mha-primary-darker: #243a66;
	--color-mha-primary-dark: #31508c;
	--color-mha-primary-base: #4d73bf;
	--color-mha-primary-light: #7ea1e6;
	--color-mha-primary-lighter: #a1b8e6;
	--color-mha-primary-lighter-alpha: 161, 184, 230;
	--color-mha-primary-lightest: #e6eeff;

	// CloudMD Palette
	--color-cmd-primary-darkest: #104040;
	--color-cmd-primary-darkest-alpha: 16, 64, 64;
	--color-cmd-primary-darker: #1a6666;
	--color-cmd-primary-dark: #269999;
	--color-cmd-primary-base: #30bfbf;
	--color-cmd-primary-light: #7ee6e6;
	--color-cmd-primary-lighter: #a1e6e6;
	--color-cmd-primary-lightest: #e6ffff;
	--color-cmd-primary-lighter-alpha: 161, 230, 230;

	// Kii Palette
	--color-kii-light-teal: #76BCB7;
	--color-kii-mental-health-primary: #BCEEEA;
	--color-kii-mental-health-secondary: #BCEEEA80;
	--color-kii-medical-care: #365D62;
	--color-kii-resource-library: #FFC701;
	--color-kii-resource-library-light-alpha: #FFC7011A;


	// Shared variables
	--color-primary-darkest: var(--color-mha-primary-darkest);
	--color-primary-darkest-alpha: var(--color-mha-primary-darkest-alpha);
	--color-primary-darker: var(--color-mha-primary-darker);
	--color-primary-dark: var(--color-mha-primary-dark);
	--color-primary-base: var(--color-mha-primary-base);
	--color-primary-light: var(--color-mha-primary-light);
	--color-primary-lighter: var(--color-mha-primary-lighter);
	--color-primary-lightest: var(--color-mha-primary-lightest);
	--color-primary-lighter-alpha: var(--color-mha-primary-lighter-alpha);

	--color-secondary-darkest: #203305;
	--color-secondary-darker: #304d08;
	--color-secondary-dark: #48730b;
	--color-secondary-base: #60990f;
	--color-secondary-light: #86b347;
	--color-secondary-lighter: #a9cc7a;
	--color-secondary-lightest: #eaffcc;

	--color-primary-border: var(--color-primary-dark);
	--color-primary-hover: var(--color-primary-dark);
	--color-reminder-hover: var(--color-primary-dark);
	--ion-color-primary: var(--color-primary-base);

	@include corporate-color-set();
	@include whitelabel-variables-corporate();

	#app.cloud-md {
		--color-primary-darkest: var(--color-cmd-primary-darkest);
		--color-primary-darkest-alpha: var(--color-cmd-primary-darkest-alpha);
		--color-primary-darker: var(--color-cmd-primary-darker);
		--color-primary-dark: var(--color-cmd-primary-dark);
		--color-primary-base: var(--color-cmd-primary-base);
		--color-primary-light: var(--color-cmd-primary-light);
		--color-primary-lighter: var(--color-cmd-primary-lighter);
		--color-primary-lightest: var(--color-cmd-primary-lightest);
		--color-primary-lighter-alpha: var(--color-cmd-primary-lighter-alpha);

		--ion-color-primary: var(--color-primary-base);

		--color-primary-border: var(--color-primary-dark);
		--color-primary-hover: var(--color-primary-dark);
		--color-reminder-hover: var(--color-primary-dark);

		--color-secondary-darkest: var(--color-mha-primary-darkest);
		--color-secondary-darker: var(--color-mha-primary-darker);
		--color-secondary-dark: var(--color-mha-primary-dark);
		--color-secondary-base: var(--color-mha-primary-base);
		--color-secondary-light: var(--color-mha-primary-light);
		--color-secondary-lighter: var(--color-mha-primary-lighter);
		--color-secondary-lightest: var(--color-mha-primary-lightest);

		@include corporate-color-set();
		@include whitelabel-variables-corporate();
	}

	// Common Palette
	--color-neutral-black: #17181a;
	--color-neutral-darkest: #2e3033;
	--color-neutral-darkest-alpha: 46, 48, 51;
	--color-neutral-darker: #45474d;
	--color-neutral-dark: #5c5f66;
	--color-neutral-dark-grey: #737780;
	--color-neutral-grey: #919399;
	--color-neutral-light-grey: #aaacb3;
	--color-neutral-light: #c2c5cc;
	--color-neutral-lighter: #d2d3d6;
	--color-neutral-lightest: #f2f4f7;
	--color-neutral-white: #ffffff;

	--color-red-darkest: #401616;
	--color-red-darker: #661a1a;
	--color-red-dark: #991f1f;
	--color-red-base: #cc2929;
	--color-red-light: #e65c5c;
	--color-red-lighter: #e68a8a;
	--color-red-lightest: #ffcccc;
	--color-red-light-alpha: 230, 92, 92;

	--color-orange-darkest: #4d2a08;
	--color-orange-darker: #80460d;
	--color-orange-dark: #b36212;
	--color-orange-base: #e67e17;
	--color-orange-light: #e69545;
	--color-orange-lighter: #f2b679;
	--color-orange-lightest: #ffd9b3;

	--color-yellow-darkest: #4d420f;
	--color-yellow-darker: #806e19;
	--color-yellow-dark: #b39b24;
	--color-yellow-base: #e6c72e;
	--color-yellow-light: #e6cf5c;
	--color-yellow-lighter: #f2e291;
	--color-yellow-lightest: #fff7cc;

	--color-lime-darkest: #243811;
	--color-lime-darker: #406619;
	--color-lime-dark: #609926;
	--color-lime-base: #80cc33;
	--color-lime-light: #a6e667;
	--color-lime-lighter: #bde695;
	--color-lime-lightest: #e6ffcc;

	--color-semantic-info-dark: var(--color-primary-darker);
	--color-semantic-info-base: var(--color-primary-dark);
	--color-semantic-success-dark: var(--color-primary-darker);
	--color-semantic-success-base: var(--color-primary-dark);
	--color-semantic-warning-dark: var(--color-orange-darker);
	--color-semantic-warning-base: var(--color-orange-dark);
	--color-semantic-error-dark: var(--color-red-darker);
	--color-semantic-error-base: var(--color-red-dark);
}

// MyHealthAccess Palette
// deprecated - use css variables above
$color-primary-darkest: #16243f;
$color-primary-darker: #243a66;
$color-primary-dark: #31508c;
$color-primary-base: #4d73bf;
$color-primary-light: #7ea1e6;
$color-primary-lighter: #a1b8e6;
$color-primary-lightest: #e6eeff;

$color-secondary-darkest: #203305;
$color-secondary-darker: #304d08;
$color-secondary-dark: #48730b;
$color-secondary-base: #60990f;
$color-secondary-light: #86b347;
$color-secondary-lighter: #a9cc7a;
$color-secondary-lightest: #eaffcc;

$color-neutral-black: #17181a;
$color-neutral-darkest: #2e3033;
$color-neutral-darker: #45474d;
$color-neutral-dark: #5c5f66;
$color-neutral-dark-grey: #737780;
$color-neutral-grey: #919399;
$color-neutral-light-grey: #aaacb3;
$color-neutral-light: #c2c5cc;
$color-neutral-lighter: #d2d3d6;
$color-neutral-lightest: #f2f4f7;
$color-neutral-white: #ffffff;

$color-red-darkest: #401616;
$color-red-darker: #661a1a;
$color-red-dark: #991f1f;
$color-red-base: #cc2929;
$color-red-light: #e65c5c;
$color-red-lighter: #e68a8a;
$color-red-lightest: #ffcccc;

$color-orange-darkest: #4d2a08;
$color-orange-darker: #80460d;
$color-orange-dark: #b36212;
$color-orange-base: #e67e17;
$color-orange-light: #e69545;
$color-orange-lighter: #f2b679;
$color-orange-lightest: #ffd9b3;

$color-yellow-darkest: #4d420f;
$color-yellow-darker: #806e19;
$color-yellow-dark: #b39b24;
$color-yellow-base: #e6c72e;
$color-yellow-light: #e6cf5c;
$color-yellow-lighter: #f2e291;
$color-yellow-lightest: #fff7cc;

$color-lime-darkest: #243811;
$color-lime-darker: #406619;
$color-lime-dark: #609926;
$color-lime-base: #80cc33;
$color-lime-light: #a6e667;
$color-lime-lighter: #bde695;
$color-lime-lightest: #e6ffcc;

$color-green-darkest: #0d3313;
$color-green-darker: #1a6626;
$color-green-dark: #238c35;
$color-green-base: #30bf48;
$color-green-light: #62d975;
$color-green-lighter: #95e6a3;
$color-green-lightest: #ccffd4;

$color-teal-darkest: #0d3326;
$color-teal-darker: #165943;
$color-teal-dark: #238c69;
$color-teal-base: #30bf8f;
$color-teal-light: #62d9b1;
$color-teal-lighter: #95e6cb;
$color-teal-lightest: #ccffee;

$color-cyan-darkest: #0d3333;
$color-cyan-darker: #165959;
$color-cyan-dark: #238c8c;
$color-cyan-base: #30bfbf;
$color-cyan-light: #62d9d9;
$color-cyan-lighter: #95e6e6;
$color-cyan-lightest: #ccffff;

$color-blue-darkest: #102f40;
$color-blue-darker: #164259;
$color-blue-dark: #23678c;
$color-blue-base: #308dbf;
$color-blue-light: #62afd9;
$color-blue-lighter: #8ac5e6;
$color-blue-lightest: #ccedff;

$color-indigo-darkest: #1a2040;
$color-indigo-darker: #1f2b66;
$color-indigo-dark: #263999;
$color-indigo-base: #334dcc;
$color-indigo-light: #5c73e6;
$color-indigo-lighter: #8a99e6;
$color-indigo-lightest: #ccd5ff;

$color-violet-darkest: #231b4d;
$color-violet-darker: #302080;
$color-violet-dark: #432db3;
$color-violet-base: #5639e6;
$color-violet-light: #7c67e6;
$color-violet-lighter: #ac9df2;
$color-violet-lightest: #d4ccff;

$color-grape-darkest: #301640;
$color-grape-darker: #4b1f66;
$color-grape-dark: #6d2699;
$color-grape-base: #9133cc;
$color-grape-light: #a15ccc;
$color-grape-lighter: #c795e6;
$color-grape-lightest: #ebccff;

$color-pink-darkest: #401624;
$color-pink-darker: #661933;
$color-pink-dark: #99264d;
$color-pink-base: #cc3366;
$color-pink-light: #e66791;
$color-pink-lighter: #e6a1b8;
$color-pink-lightest: #ffccdd;

// Additional colours and
// colour variables based on the palette above
$semantic-info-base: $color-primary-light;
$semantic-info-light: $color-primary-lightest;
$semantic-success-base: $color-secondary-base;
$semantic-success-light: $color-secondary-lightest;
$semantic-warning-base: $color-orange-base;
$semantic-warning-light: $color-orange-lightest;
$semantic-error-base: $color-red-base;
$semantic-error-light: $color-red-lightest;

$color-white-transparent: #ffffff85;

$color-green-hover: $color-green-dark;
$color-green-active: $color-green-dark;
$color-yellow-hover: $color-yellow-dark;
$color-orange-hover: $color-orange-dark;
$color-red-hover: $color-red-dark;
$color-red-transparent: #cc000090;

$color-critical-button: $color-red-dark;
$color-critical-hover: $color-red-darker;

$color-warning-button: $color-orange-hover;
$color-warning-hover: $color-orange-darker;




