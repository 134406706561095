
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.title {
		font-weight: 400;
		font-size: 36px;
		font-family: Marcellus, sans-serif;
		color: var(--color-kii-medical-care)
	}

	.subtitle {
		@extend .title;
		font-weight: 400;
		font-size: 20px;
	}

	.background-white {
		background-color: white;
	}

	.disclaimer {
		font-family: EuclidCircularA, sans-serif;
		font-weight: 300;
		font-size: 14px;
		line-height: 21px;
		text-align: center;
		color: var(--color-kii-medical-care);
		height: 63px;
		left: 30px;
		right: 30px;
	}

