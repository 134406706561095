
<template>
	<div class="m-4">
		<ion-button fill="clear"
								class="star-button">
			<i class="fa-3x"
				 :class="filled === true ? 'fa-solid fa-star': 'fa-regular fa-star'"></i>

		</ion-button>
	</div>
</template>

<script lang="ts">
	import {Options, Prop, Vue} from "vue-property-decorator";
	import { IonButton, IonIcon } from "@ionic/vue";

	@Options({components: {IonButton, IonIcon}})
	export default class Star extends Vue
	{
		@Prop({type: Boolean}) filled: boolean;
	}

</script>

<style scoped>
.star-button {
	height: 46px;
	width: 46px;
	animation: none;
}

</style>
