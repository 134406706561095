
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


	.notifier {
		width: 100%;
		z-index: 1000000; // must show in front of modals
		position: fixed;
		top: 0;
		display: flex;
		justify-content: center;
		transition: transform .2s ease;
		cursor: pointer;

		:deep(*).banner-notification-item {
			z-index: 1001;
		}

		$input_padding: 4px;
		.button-row {
			display: flex;
			flex-direction: row;
			margin: 0 -$input_padding;

			.button-container {
				max-width: inherit;
				flex: 1 1 100%;

				:deep(*) ion-button {
					width: 100%;
				}

				&:not(:first-child) {
					margin-left: 8px;
				}
			}
		}
	}

