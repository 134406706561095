.home-page-header {

	$header-container-height: 56px;

	:deep(*).header-container {
		.header {
			height: 100%;
		}
	}
	.header-container {
		position: relative;

		&.notification-bar-offset {

		}
		.dropdown-container {
			display: flex;
			flex-direction: row;
			align-items: center;

			font-size: 22px;
		}

		.account-text ion-button {
			--padding-start: 0;
			--padding-end: 0;
			font-size: 22px;
		}
		.logout-text ion-button {
			font-size: 14px;
		}

		i {
			font-size: 20px;
			margin: 0 4px;

			&.icon-mha {
				font-size: 28px;
				margin-left: 0;
			}
		}

		.dropdown {
			border: $border-light-grey;
			border-radius: $border-radius-default;
			background-color: white;
			width: 200px;
			position: absolute;
			z-index: $layer-top;
			top: $header-container-height;
			overflow: hidden;

			.item {
				width: 100%;
				height: 50px;
				cursor: pointer;
				display: flex;
				align-items: center;

				& > * {
					margin-left: 13px;
				}

				img {
					width: 24px;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				span {
					flex-grow: 1;
					font-size: 15px;
				}

				&:hover {
					background-color: var(--color-whitelabel-primary-base);
					font-family: var(--app-font-bold), serif;
					color: white;
				}
			}
		}
	}
}
