
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.close-button {
		height: 20px;
		width: 20px;
	}
	.prompt {
		color: var(--color-kii-medical-care);
		font-family: EuclidCircularA, sans-serif;
		font-weight: 400;
		font-size: 16px;
	}

