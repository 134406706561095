
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.desktop-account-button {
		i {
			float: left;
			font-size: 32px;
		}

		:deep(*).button {
			:deep(*).button-inner {
				display: flex;
				justify-content: space-between;
			}
		}
	}

	/* Media Queries */
	@media only screen and (min-width: 769px) {
		.home-mobile-footer {
			display: none;
		}
	}
	@media only screen and (max-width: 768px) {
		.display-desktop {
			display: none;
		}
	}
