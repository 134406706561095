
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				


	$card-width: 288px;
	$card-width-large: 304px;
	$card-height: 376px;
	$card-height-large: 408px;

	.review-cards {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		width: 100%;

		.card {
			width: $card-width;
			min-width: $card-width;
			height: $card-height;
			z-index: 100;
			&.center {
				width: $card-width-large;
				min-width: $card-width-large;
				height: $card-height-large;
				margin-left: 24px;
				margin-right: 24px;
			}
		}
	}

	// on small screens shrink center card (which is larger than the others)
	@media only screen and (max-width: 930px) {
		.review-cards {
			.card {
				margin-bottom: 24px;
				&.center {
					min-height: $card-height;
					height: $card-height;
					width: $card-width;

					:deep(*) .reviewer-image.large{
						margin-top: 24px;
					}
				}
			}
		}
	}
