
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.loader-container {
		position: relative;
		display: flex;
		justify-content: center;

		&.small {
			width: fit-content;
		}

		:deep(*)div#wave {
			transition: opacity 0.5s 0.35s ease;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			height: 100%;
			width: 100%;
			opacity: 0;

			&.loading {
				opacity: 1;
				z-index: 9;
			}

			.dot {
				width: 18px;
				height: 18px;
			}
		}

		.button-container {
			transition: width 0.35s ease, opacity 0.25s ease;

			&.loading {
				width: 0;
				opacity: 0;
			}
		}
	}
