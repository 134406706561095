.ion-page {
  background: white !important;
}

.chat-view {
  background: var(--app-bg);
  display: flex;
  flex-direction: column;
  position: relative;

  .chat-header {
    padding-top: env(safe-area-inset-top);
  }

  .chat-box-container {
    // outer container to round the corner of the scroll bar.
    border: 1px solid var(--color-neutral-light);
    border-radius: 8px;
    overflow: hidden;

    .chat-item-box {
      flex: 1 1 200px;

      background-color: var(--color-neutral-white);

      padding: 8px 8px 0 8px;
      overflow-y: auto;

      .chat-items {
        width: 100%;
        height: calc(100% - 128px);
        padding: 0 16px;
      }
    }
  }

  form {

    min-height: 64px;
    min-width: 200px;
    padding-bottom: calc(var(--padding-bottom) + 8px);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .chat-input {
      position: relative;
      width: 100%;
      flex-grow: 1;

      :deep(ion-textarea) {
        margin: 0;
        --background: var(--color-neutral-white);
        --padding-bottom: 8px;
        --padding-top: 16px;
        --padding-start: 24px;
        --padding-end: 98px;
        --min-height: 54px;
        border-radius: 8px;
        min-height: var(--min-height);
        border: 1px solid var(--color-neutral-light);

        textarea {
          min-height: var(--min-height);
        }
      }

      .send-btns {
        position: absolute;
        bottom: 8px;
        right: 8px;
        top: 8px;
        z-index: 2;

        .chat-input-send-btn, .chat-input-send-attach-btn {
          width: 40px;
          height: 40px;

          margin: 0;
          --box-shadow: none;
          --background-hover: var(--color-whitelabel-primary-dark);
          font-size: 24px;

          &.fab-button-disabled {
            --background: var(--color-neutral-lightest);
            --color: var(--color-neutral-dark-grey);
            --border-color: var(--color-neutral-light);
            --border-width: 1px;
            --border-style: solid;
            opacity: unset;
          }

          .icon-attachments {
            font-size: 24px;
          }

          :deep(.button-container) {
            // base button has 4px margin on bottom! override that shit
            margin-bottom: 0;
          }
        }
      }
    }

  }

}
