
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.waiting-room-overlay {
		@import "../patient_user/telehealth/scss/TeleHealthVideoControls";
		@import "../kiosk_user/kiosk";

		position: fixed;
		top: 0;
		left: 0;
		z-index: 900000;

		background-repeat: no-repeat;
		background-size: cover;
		background-color: var(--color-neutral-lightest); // in case image doesn't load

		:deep(*) .spinner-light-text {
			h4, p {
				max-width: 376px;
			}
		}

		.content-div {
			max-width: 480px;
			min-width: 480px;
			text-align: center;

			.timer-text {
				text-align: left;
				padding-left: 105px;
				font-size: 99px;
				margin: 48px 0 48px 0;
			}

			.app-button {
				margin-top: 48px;
			}
		}

		.ad-image {
			opacity: 0;
			animation: fade-in 2s ease 1s forwards;
		}

		.video-controls {
			.control-button-container {
				.control-button {
					&.disabled {
						background-color: transparent;
						border: 2px solid $color-neutral-dark;

						i {
							color: $color-neutral-dark;
						}
					}
				}
			}
		}
	}
