
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.disclaimer {
		font-family: EuclidCircularA, sans-serif;
		font-size: 1rem;
		line-height: 1.5rem;
		background-color: var(--color-kii-resource-library-light-alpha);
	}
