
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.button-row {
		display: flex;
		flex-direction: row;

		.button-container {
			max-width: inherit;
			flex: 1 1 100%;

			:deep(*) ion-button {
				width: 100%;
			}

			&:not(:first-child) {
				margin-left: 8px;
			}
		}
	}
