
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	:deep(*) .header-container {
		height: 64px;
		align-items: center;
		padding: 0 16px 0 16px;
	}
